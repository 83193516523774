import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { formatNumber, sortTableNumber } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { findTypes } from '../../actions/types';

import DeleteType from './DeleteType';

const Types = () => {

    let breadcrumbRoutes = [
        {
            activo: true,
            name: 'Types',
            path: '/types'
        }
    ];

    const dispatch =  useDispatch();

    const { list } = useSelector(state => state.types);

    const [types, setTypes] = useState([]);

    const [typeDelete, setTypeDelete] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columns = [
        {
            name: 'Name',
            sortable: true, 
            selector: row => row.name
        },
        {
            name: 'Description',
            sortable: true, 
            selector: row => row.description,
            grow: 2
        },  
        {
            name: 'Permissions',
            sortable: true, 
            sortFunction: (a, b) => sortTableNumber(a, b, 'permissions'),
            selector: row => row.permissions + '/' + row.total_permissions
        }, 
        {
            name: 'Employees',
            sortable: true, 
            sortFunction: (a, b) => sortTableNumber(a, b, 'employees_assigned'),
            selector: row => row.employees_assigned != null ? formatNumber(row.employees_assigned, 0, '', '') : 'Not defined'
        }, 
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDelete(row)}><i className="bi bi-x-lg text-danger"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/types/edit/${row._id}`}><i className="bi bi-pencil-fill text-primary"></i></Link>
                    </div>
                )
            }
        }
    ];

    /* Delete */
    const handleDelete = (type) => {
        setTypeDelete(type);
    }

    useEffect(() => {
        dispatch(findTypes());
    }, []);

    useEffect(() => {
        setTypes(list);
    }, [list]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>

            <DeleteType type={typeDelete} restablishType={handleDelete} />

            <div className="row">
                <div className="col-md-12 py-4">

                    <div className="d-flex justify-content-end">
                        <Link to="/types/add">
                            <button className="btn btn-primary">Add type</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={types}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Types;