import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { login } from '../../actions/auth';

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isAuthenticated } = useSelector(state => state.auth);

    const [loginData, setLoginData] = useState({
        username: null,
        password: null
    });

    const [showPassword, setShowPassword] = useState(false);

    /* Login */
    const onChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        })
    }

    const handleKeyPressLogin = (e) => {
        if(e.keyCode === 13){
            submitLogin(e);
        }
    }

    const submitLogin = (e) => {
        e.preventDefault();

        dispatch(login(loginData));
    }

    /* Funciones generales */
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

    useEffect(() => {
        if(isAuthenticated){
            navigate('/home');
        }
    }, [isAuthenticated]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        body{
                            background-color: #FAF3E0;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex justify-content-center py-4">
                                <a href="index.html" className="logo d-flex align-items-center w-auto">
                                    <img src="/assets/img/logo.png" alt="" />
                                    <span className="d-none d-lg-block">OnTaskTech</span>
                                </a>
                            </div>

                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="pt-4 pb-2">
                                        <h5 className="card-title text-center pb-0 fs-4">Login</h5>
                                        <p className="text-center small">Enter your username or email & password</p>
                                    </div>

                                    <form className="row g-3 needs-validation" onSubmit={submitLogin} noValidate>

                                        <div className="col-12">
                                            <label htmlFor="username" className="form-label">Username or email</label>
                                            <input type="text" name="username" className="form-control" id="username" value={loginData.username || ''} onChange={e => onChange(e)} onKeyDown={handleKeyPressLogin} required />
                                            <div className="invalid-feedback">Please enter your username.</div>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <input type="password" name="password" className="form-control" id="password" value={loginData.password || ''} onChange={e => onChange(e)} onKeyDown={handleKeyPressLogin} required />
                                            <div className="invalid-feedback">Please enter your password.</div>
                                        </div>

                                        <div className="col-12">
                                            <button className="btn btn-primary w-100" type="submit">Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Login;