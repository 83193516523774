import { combineReducers } from 'redux';

import alert from './alert';
import loader from './loader';
import breadcrumb from './breadcrumb';

import auth from './auth';
import dashboard from './dashboard';
import projects from './projects';
import users from './users';
import types from './types';
import configuration from './configuration';

export default combineReducers({
    alert, loader, breadcrumb, 
    auth, dashboard, projects, users, types, configuration
});